import React from 'react'
import './power.css';
// import './etap.css'

function PowerSystem(props) {
    return (
        <div className="powersystem-container">
            <div className="powersystem-header">
                <div className="powersystem-title">
                    <h1>POWER SYSTEM STUDY & ANALYSIS</h1>
                </div>
            </div>
            <div className="powersystem-content">
                <div className="powersystem-row">
                    <div className="powersystem-txt">
                        <div className="power-box">
                        <p>
                            Power system study & analysis are measures executed to understand 
                            the performance of power systems under specified conditions.
                            These studies are  undertaken in order to design new systems or expand 
                            existing systems. With the increasing trend of complex power systems, 
                            the objective is to provide a convenient, efficient & reliable power system 
                            under various operating conditions without  compromising quality. 
                            Using software modelling tools, these system studies ensure  optimised system performance.
                            
                        </p>
                        </div>
                    </div>
                </div>
                    <div className="powersystem-row">
                        <div className="powersystem-img-column">
                            <div className="tower-bg">
                                
                            </div>
                        </div>
                        <div className="powersystem-benefit-column">
                            <h2 className="powersystem-benefit-h2">Benefits of Power System Study</h2>
                                <ul type="square" className="types-list">
                                    <li>Technical information about your system under normal & fault conditions</li>
                                    <li>Opportunities to reduce demand and energy costs</li>
                                    <li>Reports highlighting problem areas</li>
                                    <li>Recommendations on solutions & equipment to system-wide problems</li>
                                    <li>Information on how and where energy is being used</li>
                                    <li>Reassurance of protective device ratings</li>
                                    <li>Help to avoid damage and injuries</li>
                                    <li>Knowledge of the effects of harmonics and transients</li>
                                </ul>
                        </div>
                    </div>
                {/* <div className="powersystem-row">
                    <div className="powersystem-txt-column">
                       
                    </div>
                    <div className="powersystem-img-column">

                    </div>
                </div> */}
                <div className="powersystem-row">
                <div className="powersystem-txt-column">
                    <div className="power-box">
                
                        <p><i>
                            Today’s competitive electric power market puts a premium on reliability, efficiency and low cost.
                           Our Power Engineers provide essential analysis and planning services that help you meet those goals.
                        <br/><br/>
                            3PhTech is an authorized Etap service provider with a team of qualified and experienced 
                            engineering professionals who use the latest version of Etap in the projects.</i>
                        </p>
                        </div>
                    </div>
                    
                    <div className="powersystem-img-flw-column">
                    <h2 className="powersystem-h2">Power System Study consists of:</h2>
                        
                        <div className="flows-img">
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default PowerSystem;
