import React from 'react'
import './custom.css';

function Custom(props) {
    return (
        <div className="custom-container">
            <div className="custom-header">
                <div className="custom-title">
                    <h1 className="custom-colored">Industry 4.0</h1>
                    <h1>Customized IoT Solutions</h1>
                </div>
            </div>
            <div className="custom-content">
                <div className="custom-row">
                    <p> A creative, flexible and cost-effective approach 
                        towards your digital transformation. 
                        DAVAS is a suite of hardware and software products 
                        that can be customized to meet specific needs 
                        that cannot be met by a standardized solution. 
                        DAVAS custom sensors / devices have the capability to 
                        intelligently monitor and control any of your critical parameters. 
                        Three Phase Techno Solutions  undertakes detailed 
                        study of the business before building a solution 
                        that the client needs and ensures higher RoI, improved efficiency and productivity.
</p>
                </div>
            </div>
        </div>
    )
}

export default Custom;
