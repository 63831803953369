import React  from 'react';
import Slider from '../slider/slider';
import './services.css';

const  Services = (props) => {

  
        return (
    

                <div className="service-container" id="#services" >
                    <div className="service-sec">
                        <div className="service-title">
                            Services
                        </div>
                        <Slider />
                    </div>
                </div>
        
        );

}


export default Services;