import React, { Component } from 'react';

import './head.css';


function Hero(props) {
    
        return (
            <div id="#home">
                <div className="heading">
                    <div className="hero-txt">
                        <h2 className="company-name">Three Phase Techno Solutions</h2>
                        Your Digital Transformation<br></br>Partner.
                        <a href="/davas">
                        <button type="button" value="Explore" className="explorebtn">Explore</button></a>
                    </div>
                </div>
            </div>
        );
    }

export default Hero;