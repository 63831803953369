import React from "react";
import Slider from "react-slick";
import './imgslider.css';
// import './slider.css';

function slider() {

  var images = [
    { img: './images/ems.jpg', link:'/ems' },
    { img: './images/facilites-new.jpg', link:'/facility' },
    { img: './images/etap.jpg', link:'/etap' },
    { img: './images/spcl1.jpg', link:'/service' },
  ];


  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          fade: true,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          fade: true,
          slidesToScroll: 1
        }
      }
    ]
  };

  var imgSlides =
    images.map((num, i) => (
      <a className="img-wrapper" href={num.link}  style={{textDecoration:"none" , border:"none"}} key={i} >
        <img className="slide-img"  src={num.img} />
      </a>
    ));
  return (
    <div className="carosel">
      <Slider {...settings}>
        {imgSlides}
      </Slider>
    </div>
  );
}

export default slider;