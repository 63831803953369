import React from 'react'
import './facets.css';

function Facets(props) {
    return (
        <div>
            <section className="facets">
                <h1>FACETS OF DAVAS</h1>
                <div className="facet-div">
                    <div className="facet-row">
                        <div className="facet-column">
                            <div className="box">
                                <div class="facet-content">
                                    <div className="predict-logo"></div>
                                    <p>Predictive, Preventive,<br/> MTBF Analysis</p>
                                </div>
                            </div>
                        </div>
                        <div className="facet-column">
                            <div className="box">
                                <div class="facet-content">
                                    <div className="cloud-logo"></div>
                                    <p>24*7 Real time monitoring<br/> 
                                        & Alert of Critical Parameters</p>
                                </div>
                            </div>
                        </div>
                        <div className="facet-column">
                            <div className="box-transparent">
                                
                                
                            </div>
                        </div>
                    </div>
                    <div className="facet-row">
                        <div className="facet-column">
                            <div className="box">
                                <div class="facet-content">
                                    <div className="parameter-logo"></div>
                                    <p>Controlling of Parameters<br/> to achieve Auto Control</p>
                                </div>
                            </div>
                        </div>
                        <div className="facet-column">
                            <div className="box">
                                <div class="facet-content">
                                    <div className="stati-logo"></div>
                                    <p>Resource Allocation<br/> Analysis</p>
                                </div>
                            </div>
                        </div>
                        <div className="facet-column">
                            <div className="box">
                                <div class="facet-content">
                                    <div className="dollar-logo"></div>
                                    <p>Expert advice on Improvement,<br/> Cost Analysis</p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="facet-row">
                        <div className="facet-column">
                            <div className="box">
                                <div class="facet-content">
                                    <div className="linechart-logo"></div>
                                    <p>All important Asset<br/> Data and its nature of Usage / Responsibility</p>
                                </div>
                            </div>
                        </div>
                        <div className="facet-column">
                            <div className="box">
                                <div class="facet-content">
                                    <div className="payment-logo"></div>
                                    <p>Traceability of Process Flows &<br/> Inventory Monitoring</p>
                                </div>
                            </div>
                        </div>
                        <div className="facet-column">
                            <div className="box">
                                <div class="facet-content">
                                    <div className="mobile-logo"></div>
                                    <p>Easy Process Monitoring and Control by<br/> DAVAS Mobile App</p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Facets
