import React from 'react'
import './water.css';

function Water(props) {
    return (
        <div className="water-container">
            <div className="water-header">
                <div className="water-title">
                    <h1 className="h1-colored">BlueSense</h1>
                    <h1>Intelligent Water Solutions</h1>
                </div>
            </div>
            <div className="water-content">
                <div className="water-row">
                    <p>BlueSense Smart Water Management System uses IoT solutions
                    and the latests in computing ( ML, AI, EDGE ) to intelligently
                    respond and<br/> create new opportunities from existing businesses.
                    BlueSense dramatically reduces water consumption, energy usage, <br/>manpower and other resources.</p>
                </div>
            </div>
            <div className="water-features">
                <div className="water-row">
                    <div className="water-column">
                        <div className="water-box">
                            <div className="water-circle-icon-1">

                            </div>
                            <h1>INTELLIGENT METERING</h1>
                            <p className="water-p">
                                <b className="blk">&gt;&gt;</b> Eliminate leakage and theft<br/>
                                <b className="blk">&gt;&gt;</b> Optimize usage<br/>
                                <b className="blk">&gt;&gt;</b> MInimize wastage<br/>
                                <b className="blk">&gt;&gt;</b> Improve quality<br/>
                                <b className="blk">&gt;&gt;</b> Alert system for crossing the threshold<br/>
                                <b className="blk">&gt;&gt;</b> Storage & Visualization of water consumption<br/>
                            </p>
                        </div>
                    </div>
                    <div className="water-column">
                        <div className="water-box">
                            <div className="water-circle-icon-2">

                            </div>
                            <h1>ASSET MANAGEMENT</h1>
                            <p className="water-p">
                                <b className="blk">&gt;&gt;</b>Digital mapping of infrastructure<br/>
                                <b className="blk">&gt;&gt;</b>Predictive Maintanance of machinery<br/>
                                <b className="blk">&gt;&gt;</b>Energy saving<br/>
                            </p>
                        </div>
                    </div>
                    <div className="water-column">
                        <div className="water-box">
                            <div className="water-circle-icon-3">

                            </div>
                            <h1>QUALITY MONITORING</h1>
                            <p className="water-p">
                                <b className="blk">&gt;&gt;</b>Potable water monitoring<br/>
                                <b className="blk">&gt;&gt;</b>Contamination check<br/>
                                <b className="blk">&gt;&gt;</b>Aquaculture health monitoring<br/>
                                <b className="blk">&gt;&gt;</b>Waste water / Sewage water treatment plant<br/>
                                <b className="blk">&gt;&gt;</b>Public bath / swimming pool water quality<br/>
                                <b className="blk">&gt;&gt;</b>Continous monitoring of PH, Temperature, Colour, Flow, Pressure,
                                Viscosity, Conductivity of water <br/>
                            </p>
                        </div>
                    </div>
                    <div className="water-column">
                        <div className="water-box">
                            <div className="water-circle-icon-4">

                            </div>
                            <h1>INTELLIGENT CONSERVATION</h1>
                            <p className="water-p">
                                <b className="blk">&gt;&gt;</b>Rainwater harvesting<br/>
                                <b className="blk">&gt;&gt;</b>Smart irrigation systems<br/>
                                <b className="blk">&gt;&gt;</b>Pollution control solutions<br/>
                                <b className="blk">&gt;&gt;</b>Industrial regulatory compliances<br/>
                                <b className="blk">&gt;&gt;</b>Intelligent Urban Water Solutions for Smart Cities<br/>
                            </p>
                        </div>
                    </div>
                    <div className="water-column">
                        <div className="water-box">
                            <div className="water-circle-icon-5">

                            </div>
                            <h1>FLOOD MONITORING</h1>
                            <p className="water-p">
                                <b className="blk">&gt;&gt;</b>Early Warning Notification<br/>
                                <b className="blk">&gt;&gt;</b>Continuous water level reading<br/>
                                <b className="blk">&gt;&gt;</b>Flood water impact analysis<br/>
                                <b className="blk">&gt;&gt;</b>Storm Water Management<br/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Water;
