import React, { useEffect } from 'react'
import './privacy.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




function Contact(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="ct-container">
           
            <div className="ct-header">
                <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {/* Same as */}
            <ToastContainer />
                <h1>Privacy Policy</h1>
            </div>
            <div className="contents">
                {/* Privacy Policy To be Inserted Here */}
                <div className="contents">
                <div className="ct-content-left">

  <div>
    
  </div>
  <div>Last updated: January 01, 2025</div>
  <div>
    This Privacy Policy describes Our policies and procedures on the collection,
    use and disclosure of Your information when You use the Service and tells
    You about Your privacy rights and how the law protects You.
  </div>
  <div>
    We use Your Personal data to provide and improve the Service. By using the
    Service, You agree to the collection and use of information in accordance
    with this Privacy Policy.
  </div>
  <div>Interpretation and Definitions</div>
  <div>Interpretation</div>
  <div>
    The words of which the initial letter is capitalized have meanings defined
    under the following conditions. The following definitions shall have the
    same meaning regardless of whether they appear in singular or in plural.
  </div>
  <div>Definitions</div>
  <div>For the purposes of this Privacy Policy:</div>
  <div>
    Account means a unique account created for You to access our Service or
    parts of our Service.
  </div>
  <div>
    Affiliate means an entity that controls, is controlled by or is under common
    control with a party, where "control" means ownership of 50% or more of the
    shares, equity interest or other securities entitled to vote for election of
    directors or other managing authority.
  </div>
  <div>
    Application refers to DAVAS IoT, the software program provided by the
    Company.
  </div>
  <div>
    Company (referred to as either "the Company", "We", "Us" or "Our" in this
    Agreement) refers to Three Phase Techno Solutions ( I ) Pvt Ltd, S.No -9484,
    Shop-4, Alfa Tower, Jinsi Police Chowki, Aurangabad- 431001, Maharashtra,
    India.
  </div>
  <div>Country refers to: Maharashtra, India</div>
  <div>
    Device means any device that can access the Service such as a computer, a
    cellphone or a digital tablet.
  </div>
  <div>
    Personal Data is any information that relates to an identified or
    identifiable individual.
  </div>
  <div>Service refers to the Application.</div>
  <div>
    Service Provider means any natural or legal person who processes the data on
    behalf of the Company. It refers to third-party companies or individuals
    employed by the Company to facilitate the Service, to provide the Service on
    behalf of the Company, to perform services related to the Service or to
    assist the Company in analyzing how the Service is used.
  </div>
  <div>
    Third-party Social Media Service refers to any website or any social network
    website through which a User can log in or create an account to use the
    Service.
  </div>
  <div>
    Usage Data refers to data collected automatically, either generated by the
    use of the Service or from the Service infrastructure itself (for example,
    the duration of a page visit).
  </div>
  <div>
    You means the individual accessing or using the Service, or the company, or
    other legal entity on behalf of which such individual is accessing or using
    the Service, as applicable.
  </div>
  <div>Collecting and Using Your Personal Data</div>
  <div>Types of Data Collected</div>
  <div>Personal Data</div>
  <div>
    While using Our Service, We may ask You to provide Us with certain
    personally identifiable information that can be used to contact or identify
    You. Personally identifiable information may include, but is not limited to:
  </div>
  <div>Email address</div>
  <div>First name and last name</div>
  <div>Phone number</div>
  <div>Address, State, Province, ZIP/Postal code, City</div>
  <div>Usage Data</div>
  <div>Usage Data</div>
  <div>Usage Data is collected automatically when using the Service.</div>
  <div>
    Usage Data may include information such as Your Device's Internet Protocol
    address (e.g. IP address), browser type, browser version, the pages of our
    Service that You visit, the time and date of Your visit, the time spent on
    those pages, unique device identifiers and other diagnostic data.
  </div>
  <div>
    When You access the Service by or through a mobile device, We may collect
    certain information automatically, including, but not limited to, the type
    of mobile device You use, Your mobile device unique ID, the IP address of
    Your mobile device, Your mobile operating system, the type of mobile
    Internet browser You use, unique device identifiers and other diagnostic
    data.
  </div>
  <div>
    We may also collect information that Your browser sends whenever You visit
    our Service or when You access the Service by or through a mobile device.
  </div>
  <div>Information from Third-Party Social Media Services</div>
  <div>
    The Company allows You to create an account and log in to use the Service
    through the following Third-party Social Media Services:
  </div>
  <div>• &nbsp; Google</div>
  <div>• &nbsp; Facebook</div>
  <div>• &nbsp; Instagram</div>
  <div>• &nbsp; Twitter</div>
  <div>• &nbsp; LinkedIn</div>
  <div>
    If You decide to register through or otherwise grant us access to a
    Third-Party Social Media Service, We may collect Personal data that is
    already associated with Your Third-Party Social Media Service's account,
    such as Your name, Your email address, Your activities or Your contact list
    associated with that account.
  </div>
  <div>
    You may also have the option of sharing additional information with the
    Company through Your Third-Party Social Media Service's account. If You
    choose to provide such information and Personal Data, during registration or
    otherwise, You are giving the Company permission to use, share, and store it
    in a manner consistent with this Privacy Policy.
  </div>
  <div>Use of Your Personal Data</div>
  <div>The Company may use Personal Data for the following purposes:</div>
  <div>
    To provide and maintain our Service, including to monitor the usage of our
    Service.
  </div>
  <div>
    To manage Your Account: to manage Your registration as a user of the
    Service. The Personal Data You provide can give You access to different
    functionalities of the Service that are available to You as a registered
    user.
  </div>
  <div>
    For the performance of a contract: the development, compliance and
    undertaking of the purchase contract for the products, items or services You
    have purchased or of any other contract with Us through the Service.
  </div>
  <div>
    To contact You: To contact You by email, telephone calls, SMS, or other
    equivalent forms of electronic communication, such as a mobile application's
    push notifications regarding updates or informative communications related
    to the functionalities, products or contracted services, including the
    security updates, when necessary or reasonable for their implementation.
  </div>
  <div>
    To provide You with news, special offers and general information about other
    goods, services and events which we offer that are similar to those that you
    have already purchased or enquired about unless You have opted not to
    receive such information.
  </div>
  <div>To manage Your requests: To attend and manage Your requests to Us.</div>
  <div>
    For business transfers: We may use Your information to evaluate or conduct a
    merger, divestiture, restructuring, reorganization, dissolution, or other
    sale or transfer of some or all of Our assets, whether as a going concern or
    as part of bankruptcy, liquidation, or similar proceeding, in which Personal
    Data held by Us about our Service users is among the assets transferred.
  </div>
  <div>
    For other purposes: We may use Your information for other purposes, such as
    data analysis, identifying usage trends, determining the effectiveness of
    our promotional campaigns and to evaluate and improve our Service, products,
    services, marketing and your experience.
  </div>
  <div>We may share Your personal information in the following situations:</div>
  <div>
    • &nbsp; With Service Providers: We may share Your personal information with
    Service Providers to monitor and analyze the use of our Service.
  </div>
  <div>
    • &nbsp; For business transfers: We may share or transfer Your personal
    information in connection with, or during negotiations of, any merger, sale
    of Company assets, financing, or acquisition of all or a portion of Our
    business to another company.
  </div>
  <div>
    • &nbsp; With Affiliates: We may share Your information with Our affiliates,
    in which case we will require those affiliates to honor this Privacy Policy.
    Affiliates include Our parent company and any other subsidiaries, joint
    venture partners or other companies that We control or that are under common
    control with Us.
  </div>
  <div>
    • &nbsp; With business partners: We may share Your information with Our
    business partners to offer You certain products, services or promotions.
  </div>
  <div>
    • &nbsp; With other users: when You share personal information or otherwise
    interact in the public areas with other users, such information may be
    viewed by all users and may be publicly distributed outside. If You interact
    with other users or register through a Third-Party Social Media Service,
    Your contacts on the Third-Party Social Media Service may see Your name,
    profile, pictures and description of Your activity. Similarly, other users
    will be able to view descriptions of Your activity, communicate with You and
    view Your profile.
  </div>
  <div>
    • &nbsp; With Your consent: We may disclose Your personal information for
    any other purpose with Your consent.
  </div>
  <div>Retention of Your Personal Data</div>
  <div>
    The Company will retain Your Personal Data only for as long as is necessary
    for the purposes set out in this Privacy Policy. We will retain and use Your
    Personal Data to the extent necessary to comply with our legal obligations
    (for example, if we are required to retain your data to comply with
    applicable laws), resolve disputes, and enforce our legal agreements and
    policies.
  </div>
  <div>
    The Company will also retain Usage Data for internal analysis purposes.
    Usage Data is generally retained for a shorter period of time, except when
    this data is used to strengthen the security or to improve the functionality
    of Our Service, or We are legally obligated to retain this data for longer
    time periods.
  </div>
  <div>Transfer of Your Personal Data</div>
  <div>
    Your information, including Personal Data, is processed at the Company's
    operating offices and in any other places where the parties involved in the
    processing are located. It means that this information may be transferred to
    — and maintained on — computers located outside of Your state, province,
    country or other governmental jurisdiction where the data protection laws
    may differ than those from Your jurisdiction.
  </div>
  <div>
    Your consent to this Privacy Policy followed by Your submission of such
    information represents Your agreement to that transfer.
  </div>
  <div>
    The Company will take all steps reasonably necessary to ensure that Your
    data is treated securely and in accordance with this Privacy Policy and no
    transfer of Your Personal Data will take place to an organization or a
    country unless there are adequate controls in place including the security
    of Your data and other personal information.
  </div>
  <div>Delete Your Personal Data</div>
  <div>
    You have the right to delete or request that We assist in deleting the
    Personal Data that We have collected about You.
  </div>
  <div>
    Our Service may give You the ability to delete certain information about You
    from within the Service.
  </div>
  <div>
    You may update, amend, or delete Your information at any time by signing in
    to Your Account, if you have one, and visiting the account settings section
    that allows you to manage Your personal information. You may also contact Us
    to request access to, correct, or delete any personal information that You
    have provided to Us.
  </div>
  <div>
    Please note, however, that We may need to retain certain information when we
    have a legal obligation or lawful basis to do so.
  </div>
  <div>Disclosure of Your Personal Data</div>
  <div>Business Transactions</div>
  <div>
    If the Company is involved in a merger, acquisition or asset sale, Your
    Personal Data may be transferred. We will provide notice before Your
    Personal Data is transferred and becomes subject to a different Privacy
    Policy.
  </div>
  <div>Law enforcement</div>
  <div>
    Under certain circumstances, the Company may be required to disclose Your
    Personal Data if required to do so by law or in response to valid requests
    by public authorities (e.g. a court or a government agency).
  </div>
  <div>Other legal requirements</div>
  <div>
    The Company may disclose Your Personal Data in the good faith belief that
    such action is necessary to:
  </div>
  <div>• &nbsp; Comply with a legal obligation</div>
  <div>• &nbsp; Protect and defend the rights or property of the Company</div>
  <div>
    • &nbsp; Prevent or investigate possible wrongdoing in connection with the
    Service
  </div>
  <div>
    • &nbsp; Protect the personal safety of Users of the Service or the public
  </div>
  <div>• &nbsp; Protect against legal liability</div>
  <div>Security of Your Personal Data</div>
  <div>
    The security of Your Personal Data is important to Us, but remember that no
    method of transmission over the Internet, or method of electronic storage is
    100% secure. While We strive to use commercially acceptable means to protect
    Your Personal Data, We cannot guarantee its absolute security.
  </div>
  <div>Children's Privacy</div>
  <div>
    Our Service does not address anyone under the age of 13. We do not knowingly
    collect personally identifiable information from anyone under the age of 13.
    If You are a parent or guardian and You are aware that Your child has
    provided Us with Personal Data, please contact Us. If We become aware that
    We have collected Personal Data from anyone under the age of 13 without
    verification of parental consent, We take steps to remove that information
    from Our servers.
  </div>
  <div>
    If We need to rely on consent as a legal basis for processing Your
    information and Your country requires consent from a parent, We may require
    Your parent's consent before We collect and use that information.
  </div>
  <div>Links to Other Websites</div>
  <div>
    Our Service may contain links to other websites that are not operated by Us.
    If You click on a third party link, You will be directed to that third
    party's site. We strongly advise You to review the Privacy Policy of every
    site You visit.
  </div>
  <div>
    We have no control over and assume no responsibility for the content,
    privacy policies or practices of any third party sites or services.
  </div>
  <div>Changes to this Privacy Policy</div>
  <div>
    We may update Our Privacy Policy from time to time. We will notify You of
    any changes by posting the new Privacy Policy on this page.
  </div>
  <div>
    We will let You know via email and/or a prominent notice on Our Service,
    prior to the change becoming effective and update the "Last updated" date at
    the top of this Privacy Policy.
  </div>
  <div>
    You are advised to review this Privacy Policy periodically for any changes.
    Changes to this Privacy Policy are effective when they are posted on this
    page.
  </div>
  <div>Contact Us</div>
  <div>
    If you have any questions about this Privacy Policy, You can contact us:
  </div>
  <div>By email: admin@3phtechsolutions.com</div>
  <div>
    By visiting this page on our website: https://3phtechsolutions.com/contact
  </div>
  <br />
  
</div>

                </div>
              
            </div>
        </div>
    )
}

export default Contact;
