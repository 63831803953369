import React from 'react'
import './energy.css';

function Energy(props) {
    return (
        <div className="energy-container">
            <div className="energy-header">
                <div className="energy-title">
                    <h1 className="colored">GREEN+</h1>
                    <h1>Intelligent Energy Solutions</h1>
                </div>
            </div>
            <div className="energy-content">
                <div className="energy-row">
                    <p>Discover the power of more energy at lower costs.
                    Our solution helps you tap the hidden potential
                    of your<br/> energy with minimal changes to your infrastructure.
                    Learn how to transform your energy challenges to a
                    competitive<br/> edge while integrating renewables
                    and going carbon neutral at the same time.</p>
                </div>
            </div>
            <div className="energy-features">
                <div className="energy-row">
                    <div className="energy-column">
                        <div className="energy-box">
                            <div className="power-circle-icon-1">
                                
                            </div>
                            <h1 className="colored">POWER QUALITY (PQ) </h1>
                                <h1>MONITORING & MITIGATION
                            </h1>
                            <p className="energy-p">
                                <b className="blk">&gt;&gt;</b> Track harmonics to prevent overheating of transformers<br/>
                                <b className="blk">&gt;&gt;</b> Keeps historical data & provides trend report on PQ disturbances<br/>
                                <b className="blk">&gt;&gt;</b> Expert assistance to mitigate PQ issues<br/>
                            </p>
                        </div>
                    </div>
                    <div className="energy-column">
                        <div className="energy-box">
                            <div className="power-circle-icon-2">
                                
                            </div>
                            <h1 className="colored">PRESET KPI</h1> 
                            <h1>FOR PLANT AND COMPARE ACTUAL DATA
                            </h1>
                            <p className="energy-p">
                                <b className="blk">&gt;&gt;</b> Calculate customized energy KPIs<br/>
                                <b className="blk">&gt;&gt;</b> Data Analysis and Report<br/>
                            </p>
                        </div>
                    </div>
                    <div className="energy-column">
                        <div className="energy-box">
                            <div className="power-circle-icon-3">
                                
                            </div>
                            <h1 className="colored">UTILITY BILL</h1>
                            <h1>PREDICTION & VERIFICATION
                            </h1>
                            <p className="energy-p">
                                <b className="blk">&gt;&gt;</b> Predicted bills can be benchmarked to monitor and 
                                control energy consumption levels on a real-time basis<br/>
                                <b className="blk">&gt;&gt;</b> Facilitates Alerting<br/>
                            </p>
                        </div>
                    </div>
                    <div className="energy-column">
                        <div className="energy-box">
                            <div className="power-circle-icon-4">

                            </div>
                            <h1 className="colored">EMISSION</h1> 
                            <h1>MONITORING & REDUCTION
                            </h1>
                            <p className="energy-p">
                                <b className="blk">&gt;&gt;</b> Easy and accurate CO2 monitoring
                                along with energy usage comparison<br/>
                                <b className="blk">&gt;&gt;</b> Assistance in regulatory compliance<br/>
                                <b className="blk">&gt;&gt;</b> Lead your industry in GO Green practices <br/>
                            </p>
                        </div>

                    </div>
                    <div className="energy-column">
                        <div className="energy-box">
                            <div className="power-circle-icon-5">
                                
                            </div>
                            <h1 className="colored">ELECTRICAL ENERGY</h1><h1>USAGE MONITORING & ALARMING</h1>
                            
                            <p className="energy-p">
                                <b className="blk">&gt;&gt;</b> Comparing consumption data with
                                preset threshold<br/>
                                <b className="blk">&gt;&gt;</b> Flexible and Powerful Alerting<br/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Energy;
