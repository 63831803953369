import React from 'react'
import Benefit from '../benefit/benefit';
import Facets from '../facets/facets';
import Timeline from '../timeline/timeline';
import './davas.css';

function Davas(props) {
    return (
        <>
        <div className="davas-container">
            <div className="davas-header">
                <div className="davas-title">
                    <img src="http://dc13dk7u78pyg.cloudfront.net/assets/Davas.png" />
                    {/* <h1>DAVAS</h1> */}
                    <p>
                        An end to end solution for digital transformation across Industries.
                    </p>
                </div>
            </div>
            <div className="content">
                <div className="card-row">
                        <div className="data-card">
                            <div className="data-icon"></div>
                            <div className="card-title">
                                <h3>DATA</h3>
                            </div>
                            <p>DAVAS creates autonomous processes
                                using ML & AI with the help of data mined
                                through various sensors/devices. 
                                The RIGHT data results in the RIGHT decisions.
                            </p>
                        </div>
                        <div className="data-card">
                            <div className="aqui-icon"></div>
                            <div className="card-title">
                                <h3>AQUISITION</h3>
                            </div>
                            <p>Acquire Data with our suite of 
                                hardware and software products
                                which monitor your critical parameters like Electricity, Temperature, 
                                Generator and Transformer parameters etc.

                            </p>
                        </div>
                        <div className="data-card">
                            <div className="visual-icon"></div>
                            <div className="card-title">
                                <h3>VISUALISATION</h3>
                            </div>
                            <p>Data collected through our IoT devices
                            are reserved in secure cloud premises
                            and displayed on demand via an attractive and customised dashboard.
                            </p>
                        </div>
                    
                        <div className="data-card">
                            <div className="analyze-icon"></div>
                            <div className="card-title">
                                <h3>ANALYSIS & CONTROL</h3>
                            </div>
                            <p>Provides in-depth analysis and assessment
                            via a wide variety of auto generated reports.
                            Receive real-time updates and control critical
                            functions remotely via DAVAS Mobile App or Dashboard.

                            </p>
                        </div>
                    
                    
                        <div className="data-card">
                            <div className="solution-icon"></div>
                            <div className="card-title">
                                <h3>SOLUTIONS</h3>
                            </div>
                            <p>Expert assistance in analyzing the data
                            to create a customized & comprehensive solution and handhold through deployment
                            </p>
                        </div>
                   
                
                </div>
                {/* <Timeline /> */}
                <Facets />
                <Benefit />
            </div>
        </div>
    </>
    )
}

export default Davas;











                