import React, { Component } from "react";
import Slider from "react-slick";
import './pro-slider.css';

function ProSlider() {

  var images = [
    { img: './images/ctt-slide.jpg'},
    { img: './images/clt-slide.jpg'},
    { img: './images/vibration-slide.jpg'},
    { img: './images/tmp-h-slide.jpg'},
    { img: './images/tmp-co2-slide.jpg'},
    { img: './images/air-slide.jpg'},
    { img: './images/liquid-slide.jpg'},
  ];


  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 7,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          fade: true,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          fade: true,
          slidesToScroll: 1
        }
      }
    ]
  };

  var imgSlides =
    images.map((num, i) => (
        <img className="slider-img"  src={num.img} />
    ));
  return (
    <>
    <div className="carosel color-slide">
      <div className="slider-container">
      <Slider {...settings}>
        {imgSlides}
      </Slider>
      </div>
    </div>


{/* --------------------------------------   Mobile View ------------------------------------------------- */}

    <div className="mobile-section">
      <div className="mobile-box">
          <div className="mobile-row">
              <div className="mobile-img-column">
                <div className="ctt-img">
                                
                </div>
              </div>
              <div className="mobile-txt-column">
              <h2 className="mobile-h2">Contact type Temperature Sensor</h2>
              <div className="mobile-txt">
                  <ul>
                    <li>Measures temperature of any surface which is in physical contact with the sensor</li>
                    <li>Temperature limit is -40 °C to + 128 °C</li>
                    <li>Easy mounting on any surface using clamps</li>
                    <li>Distance between transmitter and receiver can be up to 100 Meters</li>
                    <li>Wi-Fi enabled with cloud interface</li>
                    <li>Applications:  Manufacturing, Hospitality, and Process Industries</li>
                  </ul>
              </div>
            </div>
          </div>        
        </div>



        <div className="mobile-box">
          <div className="mobile-row">
              <div className="mobile-img-column">
                <div className="clt-img">
                                
                </div>
              </div>
              <div className="mobile-txt-column">
              <h2 className="mobile-h2">Contact - less Temperature Sensor</h2>
              <div className="mobile-txt">
                  <ul>
                    <li>Senses temperature without having to touch objects physically</li>
                    <li>Temperature limit is -40 °C to + 127 °C</li>
                    <li>Distance between transmitter and receiver can be up to 100 Meters</li>
                    <li>Wi-Fi enabled with cloud interface</li>
                    <li>Applications: Extensively used in Manufacturing, Process, and Hospitality
                       Industries Applications: Extensively used in Manufacturing, Process, and Hospitality Industries</li>
                  </ul>
              </div>
            </div>
          </div>        
        </div>




        <div className="mobile-box">
          <div className="mobile-row">
              <div className="mobile-img-column">
                <div className="vibration-img">
                                
                </div>
              </div>
              <div className="mobile-txt-column">
              <h2 className="mobile-h2">Vibration Sensor</h2>
              <div className="mobile-txt">
                  <ul>
                    <li>Measures vibrations / fluctuating accelerations of machines</li>
                    <li>Key to Predictive Maintenance</li>
                    <li>Wi-Fi enabled with cloud interface</li>
                    <li>Applications: Extensively used in Automotive, Health Care, Aerospace, Consumer Electronics, Mining, Oil & Gas, Power Industries</li>
                  </ul>
              </div>
            </div>
          </div>        
        </div>




        <div className="mobile-box">
          <div className="mobile-row">
              <div className="mobile-img-column">
                <div className="tmp-h-img">
                                
                </div>
              </div>
              <div className="mobile-txt-column">
              <h2 className="mobile-h2">Temperature - Humidity LoRa</h2>
              <div className="mobile-txt">
                  <ul>
                    <li>Measures temperature and humidity of any surface which is in physical contact with the sensor</li>
                    <li>Temperature limit is -40 °C to +85 °C</li>
                    <li>Humidity ranges from 0-100%</li>
                    <li>Easy mounting on any surface using clamps</li>
                    <li>Distance between transmitter and receiver can be up to 1KM</li>
                    <li>Wi-Fi enabled with cloud interface</li>
                    <li>Applications: Manufacturing, Hospitality, Process, Agriculture, Livestock Management, Refrigeration, FMCG sectors</li>
                  </ul>
              </div>
            </div>
          </div>        
        </div>




        <div className="mobile-box">
          <div className="mobile-row">
              <div className="mobile-img-column">
                <div className="tmp-co2-img">
                                
                </div>
              </div>
              <div className="mobile-txt-column">
              <h2 className="mobile-h2">Temperature - Humidity - CO2 LoRa</h2>
              <div className="mobile-txt">
                  <ul>
                    <li>Measures ambient temperature, humidity and CO2 levels</li>
                    <li>Temperature limit is -40 °C to +85 °C</li>
                    <li>Humidity ranges from 0-100%</li>
                    <li>Measures CO2 from normal CO2 level (400 ppm)</li>
                    <li>Applications: Workplaces, Hospitals</li>
                  </ul>
              </div>
            </div>
          </div>        
        </div>




        <div className="mobile-box">
          <div className="mobile-row">
              <div className="mobile-img-column">
                <div className="air-img">
                                
                </div>
              </div>
              <div className="mobile-txt-column">
              <h2 className="mobile-h2">Air Quality Monitoring Sensor</h2>
              <div className="mobile-txt">
                  <ul>
                    <li>Measures TVOC (Total Volatile Organic Components) and CO2 - determines AQI from a mixture of gases</li>
                    <li>Helps to ensure worker safety, regulatory compliance</li>
                    <li>Wi-Fi enabled with cloud interface</li>
                    <li>Applications: Workplaces, Hospitals, Manufacturing Plants, Oil & Gas, Mining Industries, Chemical Laboratories</li>
                  </ul>
              </div>
            </div>
          </div>        
        </div>





        <div className="mobile-box">
          <div className="mobile-row">
              <div className="mobile-img-column">
                <div className="liquid-img">
                                
                </div>
              </div>
              <div className="mobile-txt-column">
              <h2 className="mobile-h2">Liquid level Sensor</h2>
              <div className="mobile-txt">
                  <ul>
                    <li>Measures volume / level of water and other liquids in tanks</li>
                    <li>Applications: Water Level Management System, FMCG, Oil & Gas Industries</li>
                  </ul>
              </div>
            </div>
          </div>        
        </div>

    </div>
    </>
  );
}

export default ProSlider;