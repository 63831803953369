import React, { useState } from "react";
import { Link as LinkR } from "react-router-dom";
import "./footer.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";

function Footer(props) {


  const [mail, setMail] = useState("");
  const [clicked, setClicked] = useState(false);
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const onSubscribe = () => {
    // console.log("mail====>", mail);
    setClicked(true);

    if (mail === "" || null || undefined) {
      setClicked(false);
      return alert("Enter valid E-mail");
    } else if (!validateEmail(mail)) {
      setClicked(false);
      return alert("Invalid Email");
    } else {
      axios
        .post("https://www.3phtechsolutions.com/api/v1/subscribe", { mail })
        .then((res) => {
          // console.log("Res======>>>",res)
          if (res?.data?.status === 1) {
            setClicked(false);
            setMail("");
            toast.success("Success!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style:{top:"4em"}
            });
          }
          if (res?.data?.status === 0) {
            setClicked(false);
            if(res?.data?.msg === "Already subscribed!"){

              toast.info("Already Subscribed!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style:{top:"4em"}
              });

            } else {
            toast.error("Message failed!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style:{top:"4em"}
            });
          }
          }
        })
        .catch((err) => {
          // console.log("Error==>", err);
          setClicked(false);
          toast.error(
            "Some error with the server! Kindly wait while we fix it.",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style:{top:"4em"}
              
            }
          );
        });
    }
  };

  return (
    <div className="footer-container">
      <div className="footer-row">
        <div className="footer-left-col">
          <div className="footer-links">
            <h2>Group Companies</h2>
            <ul>
              <li>
                <a href="https://www.3phtechservices.com" target="_blank">
                  Three Phase Technical Services LLC - UAE
                </a>
              </li>
              <li>
                <a href="https://www.volkkommen.com" target="_blank">
                  Volkkommen Industries LLP - INDIA
                </a>
              </li>
            </ul>
            <div className="line"></div>
            <LinkR to="/contact" style={{ textDecoration: "none" }}>
              <button className="contact-btn">CONTACT US</button>
            </LinkR>

            <div className="cpy-right-col">
              <div>
                <ul className="social">
                  <a
                    href="https://www.facebook.com/3phtechservices/"
                    target="_blank"
                    className="fb"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="#FDB513"
                    >
                      <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z" />
                    </svg>
                  </a>
                  <a
                    href="https://twitter.com/3phtechservices?lang=en"
                    className="twitter"
                    target="_blank"
                  >
                    <svg
                      width="24"
                      height="24"
                      fill="none"
                      style={{ background: "#FDB513" }}
                    >
                      <path
                        d="M22.675 2.595a8.589 8.589 0 01-2.056 2.12 11.644 11.644 0 01-.47 3.826 11.899 11.899 0 01-1.465 3.153 12.395 12.395 0 01-2.341 2.672c-.906.774-1.997 1.392-3.275 1.852-1.277.462-2.643.692-4.099.692-2.292 0-4.39-.613-6.294-1.84.296.034.626.05.99.05 1.903 0 3.6-.583 5.089-1.75a3.997 3.997 0 01-2.386-.819 4.009 4.009 0 01-1.447-2.024c.28.042.537.063.774.063.364 0 .724-.046 1.079-.14a4.033 4.033 0 01-2.354-1.414 3.96 3.96 0 01-.933-2.608v-.05a4.066 4.066 0 001.853.52 4.094 4.094 0 01-1.333-1.46 3.994 3.994 0 01-.494-1.954c0-.745.186-1.434.558-2.069a11.62 11.62 0 003.737 3.027 11.421 11.421 0 004.715 1.263 4.553 4.553 0 01-.102-.94c0-1.133.4-2.1 1.2-2.9.799-.799 1.765-1.198 2.9-1.198 1.184 0 2.182.431 2.994 1.294a8.037 8.037 0 002.602-.99c-.313.973-.914 1.726-1.802 2.259A8.167 8.167 0 0020 2.595z"
                        fill="#1A3144"
                      ></path>
                    </svg>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/three-phase-techno-solutions-india/"
                    className="linkedin"
                    target="_blank"
                  >
                    <svg width="24" height="24" fill="none">
                      <path
                        d="M11.768 9.922v-.029s-.03 0 0 .03z"
                        fill="none"
                      ></path>
                      <path
                        d="M20.248 0H1.615C.721 0 0 .692 0 1.586v18.835c0 .866.721 1.587 1.615 1.587h18.633c.894 0 1.615-.692 1.615-1.587V1.558c0-.866-.72-1.558-1.615-1.558zM6.634 18.402H3.346V8.48h3.288v9.922zM4.99 7.124c-1.125 0-1.846-.75-1.846-1.701 0-.981.75-1.702 1.875-1.702s1.817.75 1.846 1.702c0 .923-.721 1.701-1.875 1.701zm13.557 11.278h-3.318v-5.307c0-1.327-.49-2.25-1.672-2.25-.924 0-1.443.606-1.702 1.212-.087.202-.116.519-.116.807v5.538H8.451s.058-8.999 0-9.922h3.288v1.413c.433-.663 1.212-1.644 2.971-1.644 2.164 0 3.808 1.414 3.808 4.471v5.682h.029z"
                        fill="#FDB513"
                      ></path>
                    </svg>
                  </a>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ToastContainer />
        <div className="footer-right-col">
          <div className="input-container">
            <h3>Stay Connected! Subscribe for More News and Updates </h3>
            <span className="footer-right-span">
              <input
                type="mail"
                placeholder="Your Email"
                onChange={(e) => {
                  setMail(e.target.value);
                }}
                required
              />
              <button
                className="sub-btn"
                disabled={clicked}
                onClick={onSubscribe}
              >
                SUBSCRIBE
              </button>
            </span>
          </div>
        </div>
      </div>
      {/* -------------------------------------------------------------------------------------------- */}
      <div className="cpy-text-row">
        <div className="cpy-left-col">
          <h4>
            2023 © Three Phase Techno Solutions ( I ) Pvt Ltd. All Rights
            Reserved.
          </h4>
        </div>
      </div>
    </div>
  );
}

export default Footer;
