import React from 'react'
import './temp.css';

function Temp(props) {
    return (
        <div className="temp-container">
            <div className="temp-header">
                <div className="temp-title">
                    <h1 className="temp-colored">THERMA+</h1>
                    <h1>Intelligent Temperature Management Solutions</h1>
                </div>
            </div>
            <div className="temp-content">
                <div className="temp-row">
                    <p>Therma+ Intelligent Temperature Management is a 
                        suite of customized IoT sensors, software and 
                        an easy to use dashboard. Therma+ allows authorized users 
                        to monitor and control temperature settings remotely, 
                        thus ensuring managerial oversight of 
                        critical assets at all times. This solution also comes with embedded 
                        ML/AI which can intelligently respond to changes in temperature 
                        by alerting authorized personnel and responding 
                        to such changes without waiting for external inputs.</p>
                </div>
            </div>
            <div className="temp-features">
                <div className="temp-row">
                    <div className="temp-column">
                        <div className="temp-box">
                            <div className="temp-circle-icon-1">

                            </div>
                            <h1>HEALTH & PHARMA</h1>
                            <p className="temp-p">
                                <b className="blk">&gt;&gt;</b> Remote health monitoring<br/>
                                <b className="blk">&gt;&gt;</b> Maintain shelf life and effectiveness of medication<br/>
                                <b className="blk">&gt;&gt;</b> Comply with regulations regarding storage conditions<br/>
                            </p>
                        </div>
                    </div>
                    <div className="temp-column">
                        <div className="temp-box">
                            <div className="temp-circle-icon-2">

                            </div>
                            <h1>LOGISTICS & SUPPLY CHAIN</h1>
                            <p className="temp-p">
                                <b className="blk">&gt;&gt;</b> Track temperature of containers remotely 
                                to ensure transparency in supply chain and to maintain product quality<br/>
                                <b className="blk">&gt;&gt;</b> Track and remotely change the temperature of the warehouse<br/>
                                <b className="blk">&gt;&gt;</b> Meet the required standards for transporting food and chemical products<br/>
                            </p>
                        </div>
                    </div>
                    <div className="temp-column">
                        <div className="temp-box">
                            <div className="temp-circle-icon-3">

                            </div>
                            <h1>FOOD & BEVERAGE</h1>
                            <p className="temp-p">
                                <b className="blk">&gt;&gt;</b> Ensure optimum temperature for 
                                food preparation and storage to maintain quality<br/>
                                <b className="blk">&gt;&gt;</b> Meet regulations regarding F&B storage<br/>
                                <b className="blk">&gt;&gt;</b> Ensure right temperature to reduce loss due to spoilage<br/>
                                <b className="blk">&gt;&gt;</b> Conserve energy by maintaining the right temperature<br/>
                            </p>
                        </div>
                    </div>
                    <div className="temp-column">
                        <div className="temp-box">
                            <div className="temp-circle-icon-4">

                            </div>
                            <h1>HVAC</h1>
                            <p className="temp-p">
                                <b className="blk">&gt;&gt;</b> Productivity improvement by maintaining a pleasant 
                                and healthy work environment<br/>
                                <b className="blk">&gt;&gt;</b> Predictive Maintenance of HVAC systems ensures 
                                cost savings and improved efficiency <br/>
                                <b className="blk">&gt;&gt;</b> Remote monitoring and control of HVAC systems 
                                helps to do away with manual intervention<br/>
                            </p>
                        </div>
                    </div>
                    <div className="temp-column">
                        <div className="temp-box">
                            <div className="temp-circle-icon-5">

                            </div>
                            <h1>ASSET MANAGEMENT</h1>
                            <p className="temp-p">
                                <b className="blk">&gt;&gt;</b> Ensure equipment  health by continuous monitoring of temperature<br/>
                                <b className="blk">&gt;&gt;</b> Predictive Maintenance to lower downtime<br/>
                                <b className="blk">&gt;&gt;</b> Maintain boiler temperature at optimum level<br/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Temp;
