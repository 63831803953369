import React from 'react'
import './products.css';
import ProSlider from './../product-slider/pro-slider';

function Products(props) {
    return (
        <div className="product-container">
            <div className="product-header">
                <div className="bg-box">
                    <h1 className="product-title">
                        PRODUCTS
                    </h1>
                </div>
            </div>
            <div className="product-content">
                <ProSlider/>
            </div>
        </div>
    )
}

export default Products;
