import React from 'react'
import './spl-engi.css';

function ServiceLink(props) {
    return (
        <div className="spl-container">
            <div className="spl-header">
                <div className="spl-title">
                    <h1>SPECIALIZED ENGINEERING</h1>
                </div>
            </div>
            <div className="spl-content">
                <div className="servi-row">
                    <div className="servi-column">
                        
                    </div>
                    <div className="servi-column">
                        
                    </div> 
                </div>
                <div className="spl-box">
                    <div className="spl-row">
                        <div className="spl-img-column">
                            <div className="en-audit-img">
                                
                            </div>
                        </div>
                        <div className="spl-txt-column">
                            <h2 className="audit-h2">Energy Audit</h2>
                            <div className="spl-txt">
                            According to the definition in the ISO 50002 standard, 
                            an energy audit is a systematic analysis of energy use and energy 
                            consumption within a defined energy audit scope, in order to identify, 
                            quantify and report on the opportunities for improved energy performance.
                            Therefore, an energy audit is an energy assessment.
                            Energy audit analyses energy flows in a building, process or system to reduce 
                            the amount of energy input into the system whilst 
                            maintaining or improving human comfort, health and safety.<br/>
                            Energy audits vary in depth and there are three types of energy audits 
                            based on the level of detailing required:<br/>
                            <ul className="types-list">
                                <li>Walk-Through Audit (WTA)</li>
                                <li>Energy Diagnosis</li>
                                <li>Investment Grade Audit (IGA)</li>
                            </ul>
                            </div>
                        </div>
                    </div>        
                </div>
                <div className="spl-box">
                    <div className="spl-row">
                        <div className="spl-img-column">
                            <div className="en-safety-img">
                                
                            </div>
                        </div>
                        <div className="spl-txt-column">
                            <h2 className="audit-h2">Energy Safety Audit</h2>
                            <div className="spl-txt">
                            Electrical Safety Audit aims to examine the safety of any 
                            industrial / commercial / domestic / electrical installations. 
                            The audit is performed by enquiry, inspection, testing and verification.
                            Electrical Safety Audits are conducted by our experienced qualified 
                            professionals who assist clients in reducing risk and help to ensure 
                            compliance with applicable Safety Standards and Regulations.
                            3PhTech follows a systematic approach for evaluating potential 
                            electrical hazards and produce reports recommending actions for improvement 
                            in Electrical Safety Installations.
                            </div>
                        </div>
                    </div>        
                </div>
                <div className="spl-box">
                    <div className="spl-row">
                        <div className="spl-img-column">
                            <div className="third-img">
                                
                            </div>
                        </div>
                        <div className="spl-txt-column">
                            <h2 className="audit-h2">Third Party Inspection & Testing</h2>
                            <div className="spl-txt">
                            If safe and reliable operation of a newly installed electrical power system 
                            and related components is to be achieved, several key components are required: 
                            The power system and its components must be designed and engineered correctly.
                            Quality equipments should be procured. The installation must meet all applicable 
                            codes, standards, local utility regulations and be performed by qualified contractors and vendors.
                            All information should be documented and archived for future needs.<br/>
                            Our expert team helps you with verification of all of the above through a rigorous 
                            and detailed inspection process. This enhances your competence and professionalism within the industry.
                            </div>
                        </div>
                    </div>        
                </div>
                <div className="spl-box">
                    <div className="spl-row">
                        <div className="spl-img-column">
                            <div className="scanning-img">
                                
                            </div>
                        </div>
                        <div className="spl-txt-column">
                            <h2 className="audit-h2">Infrared Scanning</h2>
                            <div className="spl-txt">
                            Infrared electrical inspections detect system overloads, 
                            loose / improper joint connections, components and switchgear failures.
                            Increased heating is a sign of failure and infrared is the best diagnostic tool 
                            available to detect the same. Finding these hot connections in the early stages of 
                            degeneration helps to prevent component failure, safety hazards and productivity loss.
                            Thus, Electrical Infrared Surveys improve Safety, Reliability & Reduce Down Time.
                            </div>
                        </div>
                    </div>        
                </div>
            </div>
        </div>
    )
}

export default ServiceLink;
