import React from 'react'
import './about.css';

function About(props) {
    return (
        <div className="about-container" id="#about">
            <div className="row">
                <span className="sub-heading">
                    <h3>About Us</h3>
                    <div className="bar"></div>
                </span>

            </div>
            <div class="row">
                <div class="column">
                    <div className="about-pics">
                        <div className="image-container"> </div>
                        <div className="bg-container"></div>
                    </div>
                    <p className="about-txt">TPTS is a digital transformation partner with end to end solutions
                    comprising hardware and software. Our suite of products can be tailored to fit a
                    wide variety of sectors like Manufacturing, Infrastructure, Logistics, Hospitality etc.
                    Our IoT based devices combined with ML, AI software are at the cutting edge of innovation.
                    Our ecosystem of products allow our customers to adapt to the changing business environments
                        and create value for themselves and their customers.</p>
                </div>
            </div>

            {/* ---------------------------------------------------------- Our Vision ---------------------------------------- */}

            <div className="row">
                <span className="vision-heading">
                    <div className="bar"></div>
                    <h3>Our Vision</h3>
                </span>
            </div>
            <div class="row">

                <div class="column">
                    <p className="txt-vision">
                        Our purpose is to help you to be the best version of yourself you can be.
                        Our consultants and engineers create simple and elegant solutions that
                        make your business future ready.
                    </p>
                    <div className="ourVision-pics">
                        <div className="bkg-container"></div>
                        <div className="img-container"></div>
                    </div>
                </div>
            </div>


            {/* ---------------------------------------------------  Our Team  -------------------------------------------------------- */}


            <div className="team-container">
                <div className="team-h2">
                    <h3>Our Team</h3>
                </div>
                <div className="team-p">
                    <p>
                        When you choose TPTS you get more than a customised product or service, you get a team of professionals invested in ensuring your company's transformation through continuous refinement.
                        Our founders have experience working with global brands in multiple geographies and sectors.
                        In their entrepreneurial career they have built an Engineering Services Company from the ground up and turned it into a trusted brand in the Middle East and India.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default About;

