import React from 'react'
import './asset.css';

function Asset(props) {
    return (
        <div className="asset-container">
            <div className="asset-header">
                <div className="asset-title">
                    <h1 className="asset-colored">ASSET+</h1>
                    <h1>Intelligent Asset<br/>Management Solutions</h1>
                </div>
            </div>
            <div className="asset-content">
                <div className="asset-row">
                    <p>ASSET+ Intelligent Asset Management  is a holistic solution
                         that monitors your assets 24*7 using IoT devices, 
                         this generates real-time  data on your assets. 
                         Our integrated ML / AI algorithms use this data
                          and provide actionable insights on predictive maintenance,
                           asset safety, inventory etc.This massively improves 
                           your OEE and RoI through better asset quality and lower downtime. 
                          All your asset data is available at your 
                           fingertips through our dashboard. The customer can track, 
                           trace and  manage assets at different sites using this dashboard.</p>
                </div>
            </div>
            <div className="asset-features">
                <div className="asset-row">
                    <div className="asset-column">
                        <div className="asset-box">
                            <div className="asset-circle-icon-1">

                            </div>
                            <h1>REAL-TIME ASSET TRACKING</h1>
                            <p className="asset-p">
                                <b className="blk">&gt;&gt;</b>24 * 7 real-time tracking of asset status,
                                condition and positioning<br/>
                                <b className="blk">&gt;&gt;</b>Track expensive and heavy equipment such as trucks, 
                                shipping containers<br/>
                                <b className="blk">&gt;&gt;</b>Monitor oversights and alerting system on crossing threshold<br/>
                                <b className="blk">&gt;&gt;</b>Deliver sensitive materials safely, 
                                on time and in perfect condition, recovery plan for loss/theft<br/>
                                <b className="blk">&gt;&gt;</b>Real-time notification on used hours and assess usage trends<br/>
                                <b className="blk">&gt;&gt;</b>Quick and easy access to the assets<br/>
                                <b className="blk">&gt;&gt;</b>Track assets along the supply chain and 
                                valuable insights into the value chain journey<br/>
                            </p>
                        </div>
                    </div>
                    <div className="asset-column">
                        <div className="asset-box">
                            <div className="asset-circle-icon-2">

                            </div>
                            <h1>SMART MAINTANANCE</h1>
                            <p className="asset-p">
                                <b className="blk">&gt;&gt;</b>Reduce down time and stop asset failures<br/>
                                <b className="blk">&gt;&gt;</b>Increase asset life and efficiency<br/>
                                <b className="blk">&gt;&gt;</b>Reduce human resource involvement<br/>
                                <b className="blk">&gt;&gt;</b>Reduce maintenance cost and high Return on Investment (RoI)<br/>
                                <b className="blk">&gt;&gt;</b>Enhance safety<br/>
                            </p>
                        </div>
                    </div>
                    <div className="asset-column">
                        <div className="asset-box">
                            <div className="asset-circle-icon-3">

                            </div>
                            <h1>QUALITY</h1>
                            <p className="asset-p">
                                <b className="blk">&gt;&gt;</b> Control quality of delivery<br/>
                                <b className="blk">&gt;&gt;</b> Improve customer experience<br/>
                                <b className="blk">&gt;&gt;</b> Optimize transport routes and cost<br/>
                                <b className="blk">&gt;&gt;</b> Reduce cost of losses and theft<br/>
                                <b className="blk">&gt;&gt;</b> Eliminate human error<br/>
                                <b className="blk">&gt;&gt;</b> Increase speed and  efficiency of the inventory 
                                process with  optimized cost<br/>
                                <b className="blk">&gt;&gt;</b> Improve transfer of the assets along the supply chain<br/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Asset;
