import React, { useState, } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import {Link} from 'react-router-dom';
import './nav.css';

function Navi(props) {

    const [click, setClick] = useState(false);

    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);

    const closeMenu = () => setClick(false);

    const showButton = () => {
        if (window.innerWidth <= 1060) {
            setButton(false)
        } else {
            setButton(true)
        }
    }

    

    window.addEventListener('resize', showButton);

    return (
        <>
            <div className="navbar">
                <Link to="/" className="navbar-logo" onClick={closeMenu}>

                </Link>
                <div className="navbar-container">

                    <div className="menu-icon" onClick={handleClick} >
                        {click ? <FaTimes /> : <FaBars />}
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className="nav-item">
                            <Link
                               activeClass="active"
                               to="/"
                               spy={true}
                               smooth={false}
                               offset={0}
                               duration={500} 
                               className="nav-links" 
                               onClick={closeMenu}
                               >
                               Home
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link
                               to="/#about"
                               spy={true}
                               smooth={false}
                               offset={0}
                               delay={10}
                               isDynamic={true}
                               duration={300} 
                               className="nav-links" 
                               onClick={closeMenu}
                               >
                               About
                            </Link>
                        </li>
                        
                        <li className="dropdown nav-item">
                            <Link
                                to="#"
                                spy={true}
                                smooth={false}
                                offset={-70}
                                duration={500}
                                className="nav-links" 
                                
                                >
                                Solutions

                                </Link>
                            <div className="dropdown-content">
                                <a href="/davas">DAVAS - A Comprehensive Digital Solution</a>
                                <a href="/energy">GREEN+ Intelligent Energy Solutions</a>
                                <a href="/water">BlueSense Water Solutions</a>
                                <a href="/temp">THERMA+ Temperature Management Solutions</a>
                                <a href="/asset">ASSET+ Intelligent Asset Management Solutions</a>
                                <a href="/custom">Industry 4.0 Customized IoT Solutions</a>
                                
                            </div>
                        </li>
                        <li className="dropdown nav-item">
                            <Link 
                                to="#"
                                spy={true}
                                smooth={false}
                                offset={-70}
                                duration={500} 
                                className="nav-links" 
                                >
                                Services
                                </Link>
                                <div className="dropdown-content">
                                <a href="/ems">Energy Management System</a>
                                <a href="/etap">Power System Study</a>
                                <a href="/service">Specialized Engineering</a>
                                <a href="/facility">Facilities Support</a>
                                {/* <a href="/service">Energy Audit</a>
                                <a href="/service">Electrical Safety Audit</a>
                                <a href="/service">Third Party Inspection & Testing</a>
                                <a href="/service">Infrared Scanning (Certified Thermography)</a>
                                <a href="/service">LV Switchgear Service &Maintenance</a>
                                <a href="/service">Capacitor Bank Service & Maintenance </a>
                                <a href="/service">ACB Repair & Rectification </a>
                                <a href="/service">VFD Repair & Rectification</a>
                                <a href="/service">Retrofitting & Refurbishment of LV Switch Gear System</a> */}
                                {/* <a href="https://3phtechservices.com/electro-mechanical-equipment-installation-maintenance/">Equipment Installation and Maintanance</a> */}
                                {/* <a href="https://3phtechservices.com/electrical-installation-for-lv-systems/">Field Contracting</a> */}
                                
                            </div>
                        </li>
                        <li className="dropdown nav-item">
                            <Link
                                to="/products"
                                spy={true}
                                smooth={false}
                                offset={-70}
                                duration={500}
                                className="nav-links" 
                                
                                >
                                Products
                            </Link>
                            <div className="dropdown-content">
                                <a href="/products">Contact type Temperature Sensor</a>
                                <a href="/products">Contact-less Temperature Sensor</a>
                                <a href="/products">Air Quality Monitoring Sensor</a>
                                <a href="/products">Vibration Sensor</a>
                                <a href="/products">Temperature-Humidity LoRa</a>
                                <a href="/products">Temperature-Humidity- CO2 LoRa</a>
                                <a href="/products">Liquid level Sensor</a>
                            </div>
                        </li>
                        <li className="nav-item">
                            <Link
                               to="/contact"
                               className="nav-links" 
                               onClick={closeMenu}
                               >
                               Contact Us
                            </Link>
                        </li>
                        
                        
                    </ul>
                </div>
            </div>
        </>
    );
}


export default Navi;