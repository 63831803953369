import React from "react";
import "./clients.css";
import Slider from "react-slick";
const Clients = (props) => {
  const clientsArr = [
    "http://dc13dk7u78pyg.cloudfront.net/client-logo/dubai-british-school.jpg",
    "http://dc13dk7u78pyg.cloudfront.net/client-logo/emaar-logo.svg",
    "http://dc13dk7u78pyg.cloudfront.net/client-logo/fujirah-3.jpeg",
    "http://dc13dk7u78pyg.cloudfront.net/client-logo/lulu.png",
    "http://dc13dk7u78pyg.cloudfront.net/client-logo/RAK-Hospital.png",
    "http://dc13dk7u78pyg.cloudfront.net/client-logo/Rotana.jpg",
    "http://dc13dk7u78pyg.cloudfront.net/client-logo/veolia-water.png",
    "http://dc13dk7u78pyg.cloudfront.net/client-logo/AB_InBev.png",
    "http://dc13dk7u78pyg.cloudfront.net/client-logo/AECOM-Logo.png",
    "http://dc13dk7u78pyg.cloudfront.net/client-logo/air-aurangabad.jpeg",
    "http://dc13dk7u78pyg.cloudfront.net/client-logo/dmart.webp",
    "http://dc13dk7u78pyg.cloudfront.net/client-logo/goodyear.png",
    "http://dc13dk7u78pyg.cloudfront.net/client-logo/mgm-University.jpeg",
    "http://dc13dk7u78pyg.cloudfront.net/client-logo/Nuziveedu-Seeds.jpeg",
    "http://dc13dk7u78pyg.cloudfront.net/client-logo/prozone.png",
  ];

  var settings = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          fade: true,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          fade: true,
          slidesToScroll: 1
        }
      }
    ]
  };


  let imgSlides = clientsArr.map(logo => (<div>
    <img src={logo} width="200" height="200" alt="logo"/>
  </div>)
  )

  return (
    <div className="clients-container" id="#clients">
      <div className="clients-title">
        <h3>Our Clients</h3>
        <hr className="line"></hr>
      </div>
      <div className="clients-sec">
      <Slider {...settings}>
        {imgSlides}
        </Slider>
      </div>
    </div>
  );
};

export default Clients;
