import React from 'react'
import './benefit.css';

function Benefit(props) {
    return (
        <>
            <section className="benefit">
                <h1>HOW DOES YOUR ORGANIZATION BENEFIT ?</h1>
                <div className="benefit-div">
                <div className="benefit-row">
                    <div className="benefit-column-left">
                        <h4>EFFICIENT WORKFLOW MANAGEMENT</h4>
                        <p>Analysis of acquired data enables improvements in processes by benchmarking to global industry standards</p>
                    </div>
                    <div className="benefit-column-right">
                        <div className="icon-workflow">

                        </div>
                    </div>
                </div>
                <div className="benefit-row">
                    <div className="benefit-column-left">
                        <div className="icon-location">
                            
                        </div>
                    </div>
                    <div className="benefit-column-right">
                        <h4>SINGLE WINDOW MULTI-LOCATIONS</h4>
                        <p>Multi site real-time data is available at your fingertips</p>
                    </div>
                </div>
                <div className="benefit-row">
                    <div className="benefit-column-left">
                        <h4>REDUCE OPERATIONAL COSTS & MAXIMIZE REVENUE GROWTH</h4>
                        <p>Increase productivity through reduced downtime and streamlined processes<br/> 
                        Save site visits </p>
                    </div>
                    <div className="benefit-column-right">
                        <div className="icon-revenue">
                            
                        </div>
                    </div>
                </div>
                <div className="benefit-row">
                    <div className="benefit-column-left">
                        <div className="icon-communication">
                            
                        </div>
                    </div>
                    <div className="benefit-column-right">
                        <h4>IMPROVED INTERNAL AS WELL AS EXTERNAL COMMUNICATION</h4>
                        <p>Our solution helps organize, prioritize and automate communication from different sources</p>
                    </div>
                </div>
                <div className="benefit-row">
                    <div className="benefit-column-left">
                        <h4>OPTIMIZE ENERGY CONSUMPTION </h4>
                        <p>Provide visibility to abnormal usage of elecricity and other utilities <br />
                            Create accountability by allocating utility costs to relevant departments </p>
                    </div>
                    <div className="benefit-column-right">
                        <div className="icon-energy">
                            
                        </div>
                    </div>
                </div>
                <div className="benefit-row">
                    <div className="benefit-column-left">
                        <div className="icon-settings">
                            
                        </div>
                    </div>
                    <div className="benefit-column-right">
                        <h4>SMART MAINTENANCE, ASSET MANAGEMENT</h4>
                        <p>Our predective maintanence tools combined with advice from our experts optimize maintanence and increase the life of the assets </p>
                    </div>
                </div>
                <div className="benefit-row">
                    <div className="benefit-column-left">
                        <h4>OVERALL EQUIPMENT EFFECTIVENESS (OEE) GROWTH</h4>
                        <p>Real-time data, better asset management through predective maintanence ensures drastic improvement in OEE </p>
                    </div>
                    <div className="benefit-column-right">
                        <div className="icon-growth">
                            
                        </div>
                    </div>
                </div>
                </div>
            </section>
        </>
    )
}

export default Benefit;
