import React from 'react';
import Hero from '../head/hero';
import About from '../about/about';
import Services from '../services/services';
import ScrollHandler from '../navigation/scrollHandler';
import Clients from '../clients/clients';


function Main(props) {
    
    return (<>      
        
        <Hero />
        <Services />
        <About  />
        <Clients />
    </>)

}

export default Main;