import React from 'react'
import './facility.css';

function ServiceLink(props) {
    return (
        <div className="fac-container">
            <div className="fac-header">
                <div className="fac-title">
                    <h1>FACILITIES SUPPORT</h1>
                </div>
            </div>
            <div className="fac-content">
                <div className="fac-box">
                    <div className="fac-row">
                        <div className="fac-img-column">
                            <div className="lv-img">
                               
                            </div>
                        </div>
                        <div className="fac-txt-column">
                            <h2 className="fac-h2">LV Switchgear Service & Maintenance</h2>
                            <div className="fac-txt">
                                Neglecting the maintenance of electrical equipment over a long period 
                                will inevitably lead to costly emergency repairs and equipment failures 
                                in addition to elevated safety and property risks.
                                Be smart and use our Preventive Maintenance checklist to keep 
                                your electrical distribution system in good working order.
                            </div>
                        </div>
                    </div>        
                </div>
                <div className="fac-box">
                    <div className="fac-row">
                        <div className="fac-img-column">
                            <div className="capacitor-img">
                                
                            </div>
                        </div>
                        <div className="fac-txt-column">
                            <h2 className="fac-h2">Capacitor Bank Service & Maintenance</h2>
                            <div className="fac-txt">
                                Routine performance check for power factor correction capacitors is critical 
                                as it has direct impact on your monthly utility bill. This helps in 
                                avoiding power factor penalties, reduce demand charges, 
                                increase load carrying capacity of existing circuits, 
                                improve voltage and reduce power system losses.
                            </div>
                        </div>
                    </div>        
                </div>
                <div className="fac-box">
                    <div className="fac-row">
                        <div className="fac-img-column">
                            <div className="acb-img">
                                
                            </div>
                        </div>
                        <div className="fac-txt-column">
                            <h2 className="fac-h2">ACB Repair & Rectification</h2>
                            <div className="fac-txt">
                                Periodic ACB service is crucial for reducing downtime 
                                and improving equipment reliability. 3PhTech ACB service includes both 
                                onsite and offsite services, if ACB is withdrawable to work on it at our excellence centre.
                                During the service we disassemble the ACB to allow inspection, lubrication, 
                                repair and testing. We have a range of specialized secondary 
                                injection equipments to test electronic trip units and their parameters.
                            </div>
                        </div>
                    </div>        
                </div>
                <div className="fac-box">
                    <div className="fac-row">
                        <div className="fac-img-column">
                            <div className="vfd-img">
                                
                            </div>
                        </div>
                        <div className="fac-txt-column">
                            <h2 className="fac-h2">VFD Repair & Rectification</h2>
                            <div className="fac-txt">
                                A periodic service schedule for VFDs is important to ensure maximum 
                                availability and minimum unplanned repair costs. VFDs are 
                                often the first indicators of a process change or an application problem.
                                VFDs fail mostly due to component aging or variations in operational conditions,
                                such as temperature, humidity, load etc. While it is essential 
                                to keep VFDs clean, dry, and well-connected, a monthly / yearly 
                                thorough maintenance plan should be in place to clean the entire unit and 
                                to inspect parts. Parts replacement schedule should also be followed.
                                VFDs offer high reliability if a comprehensive approach to maintenance is ensured.
                            </div>
                        </div>
                    </div>        
                </div>
            </div>
        </div>
    )
}

export default ServiceLink;
