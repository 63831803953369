import React from 'react'
import './ems.css';

function Ems(props) {
    return (
        <div className="ems-container">
            <div className="ems-header">
                <div className="ems-title">
                    <h1>ENERGY MANAGEMENT SYSTEM</h1>
                </div>
            </div>
            <div className="ems-content">
                <div className="ems-box-row">
                    <p className="ems-txt">
                        The increasing global demand for sustainable, green business models has catapulted 
                        smart energy management up the corporate agenda.
                        Smart energy management system empowers users to master their ideal 
                        energy requirements and benefit through reduced consumption. 
                        3PhTech &#8482; &nbsp; EMS is a versatile Energy Management System that uses 
                        digital technologies and IoT solutions which infuses energy intelligence 
                        into your grid and makes your operations reliable, cost- efficient and safe.
                    </p>
                    <div className="ems-box">
                        <div className="ems-row">
                            <div className="ems-box-img-column">
                                <div className="lap-pic">
                                    
                                </div>
                            </div>
                            <div className="ems-column">
                                <p>
                                <h2 className="ems-h2">Monitor & Measure</h2>
                                    Energy consumption data is collected and measured on a real-time basis 
                                    using three phase power measurement modules. 
                                    The data thus mined can be analysed and displayed through our interactive 
                                    dashboard in various meaningful formats.<br/> 
                                    This real-time access to consumption data allows you to identify 
                                    changes in consumption patterns as a result of your actions. 
                                    This gives you a comprehensive view of your company’s energy 
                                    flows and production processes. We can help you to track, compute and manage 
                                    energy costs to give your enterprise a strategic cost advantage.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="ems-box">
                        <div className="ems-row">
                            <div className="ems-column">
                                <p>
                                    <h2 className="ems-h2">Analyse & Report</h2>
                                    EMS delivers insights that allow your business to proactively 
                                    implement measures to reduce consumption, to improve resource 
                                    efficiency and reduce the risk of outages and breakdowns.
                                    Auto generated trend reports and shadow bills show you how the 
                                    value of your consumption changes over time compared to the pre-set threshold. 
                                    It helps you in forecasting your future consumption according to the trend.
                                </p>
                            </div>
                            <div className="ems-box-img-column">
                                <div className="graph-pic">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ems-box">
                        <div className="ems-row">
                            <div className="ems-box-img-column">
                                <div className="target-pic">
                                    
                                </div>
                            </div>
                            <div className="ems-column">
                                <p>
                                    <h2 className="ems-h2">Target & Control</h2>
                                    EMS not only identifies opportunities to save energy, 
                                    but also helps you take actions to target them. 
                                    Actual electricity consumption data compared with the 
                                    pre-set thresholds can be used to indicate deviations through alerts. 
                                    Our expert team can help you in taking measures to 
                                    control your energy consumption & optimise processes.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <h1 className="ems-h1">Meet ISO 50001 Requirements</h1>
                <div className="ems-iso-row">
                    <div className="ems-iso-bg-column">
                        <div className="iso-bg">
                            
                        </div>
                    </div>
                    <div className="ems-iso-txt-column">
                        <div className="iso-txt">
                        ISO 50001 requirements apply to Energy Management Systems. 
                        Companies must now be more precise in documenting how they are improving energy efficiency.
                        We facilitate and implement ISO 50001 Energy Management Systems by establishing a framework to ensure
                        continuous improvement of energy performance.
                        </div>
                    </div>
                </div>
                <h1 className="ems-h1">A Green EM Solution</h1>
                <div className="ems-iso-row">
                    <div className="ems-iso-txt-column">
                        <div className="iso-txt">
                        Calculates, tracks and analyses your CO2 emission.
                        This tracking helps you in taking actions to reduce 
                        Carbon footprint to attain a sustainable business model.
                        </div>
                    </div>
                    <div className="ems-iso-bg-column">
                        <div className="co2-bg">
                            
                        </div>
                    </div>
                </div>
                <h1 className="h1-archi">System Achitecture</h1>
                <p className="p-archi">
                    ems combines smart energy meters, CTs, communication devices 
                    and visualisation & analytical dashboards in the most efficient way with the highest level of customisation.
                    The data acquired with hardware are visualised with the customised dashboards.
                    Data management system analyses the obtained data to get insight about your energy consumption. 
                </p>
                <h1 className="h1-archi">Benefits of EMS</h1>
                <ul type="square" className="benefit-list">
                    <li>Makes your budget smarter (Utilities Consumption)</li>
                    <li>Identify, Test, and Validate energy-saving scenarios</li>
                    <li>Monitor Your demand requirement</li>
                    <li>Create alerts for your target</li>
                    <li>Letting users know their real-time consumption alone can be responsible for a substantial reduction in energy consumption</li>
                    <li>Helps in analysing the project RoI for retrofitting projects</li>
                </ul>
            </div>
        </div>
    )
}

export default Ems;
